import type {
	ReactNode,
	HTMLAttributes, ButtonHTMLAttributes,
} from 'react'

import { forwardRef } from 'react'

import { cn } from '~/lib/cn'

const BaseButton = forwardRef<
	HTMLButtonElement,
	ButtonHTMLAttributes<HTMLButtonElement> & {
	leftSection?: ReactNode
	rightSection?: ReactNode
	size?: 'normal' | 'large'
	buttonStyle?: 'primary' | 'secondary'
}
>(({
	leftSection,
	rightSection,
	children,
	className,
	size = 'normal',
	buttonStyle = 'primary',
	...rest
}, ref) => {
	const classes = cn(
		'select-none cursor-pointer transition-transform duration-100 transform active:scale-95 rounded-lg text-center text-white focus:outline-none inline-block',
		buttonStyle === 'primary' && 'bg-blue-indigo-dye hover:bg-webteam-lighter-blue',
		buttonStyle === 'secondary' && 'bg-white hover:bg-webteam-blue border border-webteam-blue text-webteam-blue hover:text-white',
		size === 'normal' && 'text-lg px-4 py-3 md:p-2 w-full md:w-44',
		size === 'large' && 'text-xl px-16 py-4',
		className,
	)

	return (
		<button
			{...rest}
			ref={ref}
			className={classes}
		>
			{leftSection}
			<span className="select-none">
				{children}
			</span>
			{rightSection}
		</button>
	)
})

BaseButton.displayName = 'BaseButton'

export default BaseButton
