'use client'

import BaseButton from '~/components/base-button'
import useCalCom from '~/hooks/use-cal-com'

const ScheduleCallButton = () => {
	useCalCom()

	return (
		<BaseButton
			data-cal-namespace=""
			data-cal-link="team/thewebteam/intro-call"
			data-cal-config={'{"layout":"month_view"}'}
			type="button"
			className="select-none block lg:inline-block"
		>
			Schedule Call
		</BaseButton>
	)
}

export default ScheduleCallButton
