import { getCalApi } from '@calcom/embed-react'
import { useEffect } from 'react'

const useCalCom = () => {
	const runCal = async () => {
		const cal = await getCalApi({});
		cal('ui', {
			styles: {
				branding: {
					brandColor: '#000000',
				},
			},
			hideEventTypeDetails: false,
			layout: 'month_view',
		});
	}

	useEffect(() => {
		void runCal()
	}, [])
}

export default useCalCom
