'use client'

import { ArrowRightIcon } from 'lucide-react'
import useCalCom from '~/hooks/use-cal-com'
import type { ReactNode } from 'react'

const GetInTouchButton = ({
	children,
}: {
	children: ReactNode
}) => {
	useCalCom()

	return (
		<button
			data-cal-namespace=""
			data-cal-link="team/thewebteam/intro-call"
			data-cal-config={'{"layout":"month_view"}'}
			type="button"
			className="select-none inline-block rounded-lg px-4 py-2 ring-1 ring-gray-200 trasition-all hover:ring-white cursor-pointer text-2xl font-semibold text-center md:text-right group text-gray-300"
		>
			<span className="flex place-items-center space-x-2">
				<span className="group-hover:text-white">
					{children}
				</span>
				<ArrowRightIcon className="h-5 transform group-hover:text-white group-hover:translate-x-1 duration-200 ease-in-out transition-all" />
			</span>
		</button>
	)
}

export default GetInTouchButton
